import './q-list.scss';
import {Questions, Period} from "../../../config/types";
import {
    SyntheticEvent,
    useEffect,
    useState
} from "react";
import Modal from "../../Modal";
import dayjs from "dayjs";

type Props = {
    list: Questions[];
    selected: Questions[];
    questionsChart: Questions[];
    hiddenActive: boolean;
    substring: string;
    onToggle: (item: Questions | null, index: number[] | null, isDeselect?: boolean) => void;
    onSelectChart: (item: Questions) => void;
    dateRange: Period;
};

type ChatMessage = {
    text: string;
    highlightedText: string;
};

const QuestionsList = ({
                           list,
                           substring,
                           selected,
                           questionsChart,
                           hiddenActive,
                           onToggle,
                           onSelectChart,
                           dateRange
                       }: Props) => {

    type Phrase = {
        id: number;
        text: string;
    }

    type PhraseGroup = {
        label: string;
        phrases: [Phrase];
    };

    const token = localStorage.getItem('token') ?? "";
    const [phrases, setPhrases] = useState<PhraseGroup[]>([]);
    const [detail, setDetail] = useState<boolean>(false);
    const [selectionStart, setSelectionStart] = useState<number | null>(null);

    const [preselectionStart, setPreselectionStart] = useState<number | null>(null);
    const [preselectionEnd, setPreselectionEnd] = useState<number | null>(null);

    const [isDeselecting, setIsDeselecting] = useState<boolean>(false);

    const [chat, setChat] = useState<ChatMessage[] | null>(null);
    const [chatInfo, setChatInfo] = useState<string[] | null>(null);
    const [selectedId, setSelectedId] = useState<number | null>(null);

    const handleQuestionClick = async (phraseId: number, phraseText: string, topic: string) => {
        try {
            const response = await fetch(`api/chat/${phraseId}?token=${token}`);
            const data = await response.json();
            if (response.ok && data) {
                const formattedChat: ChatMessage[] = data.chat.map((message: string) => ({
                    text: message,
                    highlightedText: message
                        .replace(/^(.+?):/, (match, p1) => `<strong>${p1}:</strong>`)
                        .replace(new RegExp(`(${phraseText})`, 'gi'), '<span class="mark-text__value">$1</span>')
                }));
                setChat(formattedChat);
                setChatInfo([...data.info, topic]); // Assuming data.info is an array
                setSelectedId(phraseId);
            } else {
                setChat(null);
                setChatInfo(null);
            }
        } catch (error) {
            console.error('Failed to fetch chat data:', error);
            setChat(null);
            setChatInfo(null);
        }
    };

    function highlightPhrase(text: string, phrase: string): string {
        const regex = new RegExp(`(${phrase})`, 'gi');
        return text.replace(regex, '<span class="mark-text__value">$1</span>');
    }

    useEffect(() => {
        if (phrases.length > 0) {
            setDetail(true);
        }
    }, [phrases]);

    const truncateText = (text: string, maxLength: number) => {
        if (text.length <= maxLength) return text;
        return text.substring(0, maxLength) + '...';
    };

    const createText = (value: string, text: string) => {
        const start = value.toLowerCase().indexOf(text.toLowerCase());
        if (start < 0) {
            return text;
        }

        const prefix = value.substring(0, start);
        const end = start + text.length;
        const root = value.substring(start, end);
        const postfix = value.length > 66 ?
            (end > 66 ? "..." : value.substring(end, 64) + "...") :
            value.substring(end, value.length)

        return (
            <span className="mark-text">
        <span>{prefix}</span>
        <span className="mark-text__value">{root}</span>
        <span>{postfix}</span>
      </span>
        );
    };

    const handleDetail = async (event: SyntheticEvent, item: Questions) => {
        event.stopPropagation();
        event.preventDefault();
        const startDate = dateRange[0] ? dayjs(dateRange[0]).format('YYYY-MM-DD') : '';
        const endDate = dateRange[1] ? dayjs(dateRange[1]).format('YYYY-MM-DD') : '';

        const clusterIds = item.cluster_ids.join(',');
        const response = await fetch(`/api/phrases/?token=${token}&group_id=${item.group_id}&cluster_ids=${clusterIds}&start_date=${startDate}&end_date=${endDate}`);
        const data = await response.json();
        if (data.data)
            setPhrases(data.data);
        else
            setPhrases([])
    };

    const handleMouseDown = (index: number, event: React.MouseEvent) => {
        if (!(event.target as HTMLElement)?.className.includes("text")) {
            event.preventDefault();
            setPreselectionStart(index);
            setIsDeselecting(selected.some(sel => sel.cluster_ids[0] === list[index].cluster_ids[0]));
        }
    }

    const handleMouseOver = (index: number, event: React.MouseEvent) => {
        if (preselectionStart != null)
            setPreselectionEnd(index);
    }

    const handleMouseLeave = (event: React.MouseEvent) => {
        handleMouseUp(event);
    }

    const handleMouseUp = (event: React.MouseEvent) => {
        if (preselectionStart != null && preselectionEnd != null) {
            onToggle(null, [preselectionStart, preselectionEnd], isDeselecting);
            setSelectionStart(preselectionEnd);
        }
        setIsDeselecting(false);
        setPreselectionStart(null);
        setPreselectionEnd(null);
    }

    const handleToggle = (item: Questions, index: number, event: React.MouseEvent) => {
        if (!event.shiftKey || selectionStart == null) {
            onToggle(item, null);
        } else {
            onToggle(item, [selectionStart, index]);
        }
        setSelectionStart(index);
    }

    const forcePreselection = (index: number): boolean => {
        if (!isDeselecting)
            return true;

        if (preselectionStart === null || preselectionEnd === null)
            return true;

        const start = Math.min(preselectionStart, preselectionEnd);
        const end = Math.max(preselectionStart, preselectionEnd);

        return !(index <= end && index >= start);
    }

    const checkPreselection = (index: number): boolean => {
        if (preselectionStart === null || preselectionEnd === null)
            return false;
        const start = Math.min(preselectionStart, preselectionEnd);
        const end = Math.max(preselectionStart, preselectionEnd);

        return !(index > end || index < start);
    }

    return (
        <>
            <ul onMouseUp={(event) => handleMouseUp(event)}
                onMouseLeave={(event) => handleMouseLeave(event)}
                className={`q-list `}>
                {list.map((item, index) => (
                    <li
                        className={`q-list__item ${hiddenActive && item.isHidden && 'q-list__item--hidden'}`}
                        key={item.cluster_ids[0]}
                        onMouseOver={event => {
                            handleMouseOver(index, event)
                        }}
                    >
                        <div
                            className={`q-list__item__icon ${questionsChart.some(
                                (chart) => chart.cluster_ids[0] === item.cluster_ids[0]
                            ) && 'q-list__item__icon--selected'}`}
                            onClick={() => onSelectChart(item)}
                        >
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.561496 0.000102157C0.410847 0.00293928 0.267396 0.0650475 0.162277 0.172947C0.0571578 0.280846 -0.00114681 0.42583 1.70968e-05 0.576431V15.425C-0.000558552 15.5763 0.0588887 15.7217 0.16534 15.8294C0.271792 15.937 0.416572 15.998 0.567974 15.9992H15.4277C15.5795 15.9992 15.7251 15.9389 15.8324 15.8316C15.9397 15.7244 16 15.5789 16 15.4272C16 15.2755 15.9397 15.13 15.8324 15.0227C15.7251 14.9154 15.5795 14.8551 15.4277 14.8551H1.14241V0.576431C1.143 0.500034 1.12827 0.424293 1.0991 0.353678C1.06992 0.283063 1.02689 0.219005 0.972544 0.165285C0.918196 0.111565 0.853632 0.069272 0.782662 0.0409015C0.711693 0.0125311 0.637917 -0.00134162 0.561496 0.000102157ZM15.4148 3.71278C15.2671 3.71774 15.127 3.77962 15.0239 3.88547L9.16076 9.74589L6.70321 7.04772C6.60097 6.93659 6.45891 6.87037 6.30802 6.86349C6.15713 6.85662 6.00964 6.90965 5.8977 7.01103L2.47052 10.1495C2.41104 10.1991 2.36225 10.2602 2.3271 10.3292C2.29194 10.3982 2.27116 10.4736 2.26601 10.5508C2.26086 10.6281 2.27145 10.7055 2.29713 10.7786C2.32281 10.8516 2.36306 10.9186 2.41543 10.9757C2.4678 11.0327 2.53121 11.0785 2.60181 11.1103C2.67241 11.1421 2.74874 11.1593 2.82617 11.1608C2.9036 11.1622 2.98053 11.148 3.05228 11.1189C3.12404 11.0897 3.18914 11.0464 3.24364 10.9914L6.24971 8.24139L8.71589 10.9568C8.76785 11.0141 8.83087 11.0602 8.90115 11.0924C8.97144 11.1247 9.04753 11.1423 9.12483 11.1443C9.20213 11.1464 9.27904 11.1327 9.35091 11.1042C9.42278 11.0757 9.48812 11.0329 9.543 10.9784L15.8272 4.69276C15.9084 4.61224 15.9635 4.50928 15.9856 4.39714C16.0076 4.28501 15.9955 4.16884 15.9509 4.06362C15.9063 3.9584 15.8312 3.86895 15.7352 3.8068C15.6393 3.74466 15.5269 3.71266 15.4126 3.71494L15.4148 3.71278Z"
                                    fill="#908F90"/>
                            </svg>
                        </div>

                        <div className="form-group form-group--dashed"
                             onClick={(event) => handleToggle(item, index, event)}
                             onMouseDownCapture={(event) => handleMouseDown(index, event)}
                        >
                            <input checked={
                                (selected.some((sel) => sel.cluster_ids[0] === item.cluster_ids[0]) ||
                                    checkPreselection(index)) && forcePreselection(index)
                            }
                                   data-index={index}
                                   type="checkbox"/>
                            <label>
                <span className="text q-list__item-text" onClick={(event) => handleDetail(event, item)}>
                  {substring.length > 1 ? createText(item.label, substring) : truncateText(item.label, 66)}
                </span>
                            </label>
                        </div>

                        <span className="q-list__item__count">{item.count}</span>
                    </li>
                ))}
            </ul>
            {detail && (
                <Modal onClose={() => {
                    setDetail(false);
                    setChat(null);
                }} multipleSections={true}>
                    <div className="modal-content q-list__modal">
                        <div className="q-list__modal-section">
                            <div className="q-list__modal-scrollable">
                                {phrases.map((pGroup) => (
                                    <div className="q-list__modal-content" key={pGroup.label}>
                                        <div className="q-list__modal-title">{pGroup.label}</div>
                                        {pGroup.phrases.map((phrase) => (
                                            <div key={phrase.id}
                                                 className={`question-item ${selectedId === phrase.id ? 'selected' : ''}`}
                                                 onClick={() => handleQuestionClick(phrase.id, phrase.text, pGroup.label)}>
                                                {phrase.text}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="q-list__modal-section q-list__modal-section-wide">
                            {chat ? (
                                <div className="q-list__modal-scrollable">
                                    <div className="q-list__modal-chat_preview">
                                        {chat ? chat.map((message, index) => (
                                            <p key={index}
                                               dangerouslySetInnerHTML={{__html: message.highlightedText}}></p>
                                        )) : "Загрузка чата..."}
                                    </div>
                                    <div className="q-list__modal-chat_info">
                                        {chatInfo ? (
                                            <>
                                                ID: {chatInfo[0]}<br/>
                                                Дата: {chatInfo[2]}<br/>
                                                Оператор: {chatInfo[1]}<br/>
                                                Тематика: {chatInfo[3]}<br/>
                                            </>
                                        ) : "Загрузка информации..."}
                                    </div>
                                </div>
                            ) : (
                                <div className="q-list__modal-chat_empty">Пожалуйста выберите вопрос</div>
                            )
                            }
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default QuestionsList;
