import React from 'react';
import {useNavigate} from 'react-router-dom';
import './header.scss';

interface Props {
    forceLogout: boolean;
}

const LogoutButton = ({forceLogout}: Props) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    if (forceLogout)
        handleLogout();

    return (
        <header className="header">
            <h4>Артефакт</h4>
            <div className="buttons-right">
                <button onClick={handleLogout} className="logout-button">Выйти</button>
            </div>
        </header>
    );
};

export default LogoutButton;