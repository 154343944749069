import React, {useState, useEffect, SyntheticEvent} from 'react';
import { useNavigate } from 'react-router-dom';
import './login.scss';
import useAuth from "../../hooks/useAuth";

function LoginForm() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        if (isAuthenticated)
            navigate('/');
        }, [isAuthenticated]);

    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        try {
            const response = await fetch(`/api/auth?login=${username}&password=${password}`);
            const data = await response.json();

            if (data.success) {
                localStorage.setItem('token', data.token);
                setIsAuthenticated(true);
            } else {
                alert(data.error_message);
            }
        } catch (error) {
            console.error('Authentication error:', error);
        }
    };

    // TODO: Проверять авторизацию. Добавить текст "Вы уже авторизованы!"
    return (
        <div className="login-form">
            <h2 className="login-form__title">Авторизация</h2>
            <form onSubmit={handleSubmit}>
                <div className="login-form__input-group">
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Логин"
                        className="login-form__input"
                    />
                </div>
                <div className="login-form__input-group">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Пароль"
                        className="login-form__input"
                    />
                </div>
                <button type="submit" className="login-form__button">Войти</button>
            </form>
        </div>
    );
}

export default LoginForm;
