import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@fontsource-variable/inter';
import './main.scss';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import LoginForm from "./components/LoginForm";
import PrivateRoute from "./components/PrivateRoute";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Router>
        <Routes>
            <Route path="/login" element={<LoginForm/>}/>
            <Route
                path="*"
                element={
                    <PrivateRoute>
                        <App/>
                    </PrivateRoute>
                }/>
        </Routes>
    </Router>
  </React.StrictMode>
);
