import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Picker from "./components/DatePicker";
import ChartLine from "./components/Chart";
import QuestionsContainer from "./components/Questions/Container";
import {Questions, Period} from "./config/types";
import LoginForm from './components/LoginForm';
import PrivateRoute from './components/PrivateRoute';
import dayjs from 'dayjs';
import LogoutButton from "./components/LogoutButton";

const App = () => {
    const FROM = new Date(2024, 2, 23);
    const TO = new Date(2024, 3, 23);

    const token = localStorage.getItem('token') ?? "";
    const [isAuthorized, setIsAuthorized] = useState<boolean>(true);
    const [data, setData] = useState<Questions[]>([]);
    const [chartQuestions, setChartQuestions] = useState<Questions[]>([]);
    const [dateRange, setDateRange] = useState<Period>([FROM, TO]);

    const fetchClusters = async () => {
        const startDate = dateRange[0] ? dayjs(dateRange[0]).format('YYYY-MM-DD') : '';
        const endDate = dateRange[1] ? dayjs(dateRange[1]).format('YYYY-MM-DD') : '';

        try {
            const response = await fetch(`/api/clusters?token=${token}&start_date=${startDate}&end_date=${endDate}`);
            if (response.status === 401) {
                localStorage.removeItem('token');
                setIsAuthorized(false);
                return;
            }
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }
            const result = await response.json();
            if (result.success) {
                setData(result.data ?? []);
            } else {
                throw new Error(result.error_message || 'Unknown error');
            }
        } catch (error) {
            console.error('Failed to fetch clusters:', error);
        }
    };

    useEffect(() => {
        fetchClusters();
    }, [dateRange]);

    const handleSelectChart = (item: Questions) => {
        setChartQuestions((prev) => {
            const index = prev.findIndex((val) => val.cluster_ids[0] === item.cluster_ids[0]);
            if (index !== -1) {
                return prev.filter((_, i) => i !== index);
            } else {
                return [...prev, item];
            }
        });
    };

    const daysBetween = (startDate: Date, endDate: Date) => {
        const ms_in_day = 24 * 60 * 60 * 1000;
        return Math.round(Math.abs((endDate.getTime() - startDate.getTime()) / ms_in_day)) + 1;
    };

    const daysCount = dateRange[0] && dateRange[1] ? daysBetween(dateRange[0], dateRange[1]) : 0;

    return (
        <>
            <LogoutButton forceLogout={!isAuthorized}/>
            <main className="main">
                <Picker selectedDate={dateRange} onDateChange={setDateRange}/> {}
                <ChartLine
                    questions={chartQuestions}
                    dateRange={dateRange}
                    daysCount={daysCount}
                />
                <QuestionsContainer
                    list={data}
                    onChange={setData}
                    questionsChart={chartQuestions}
                    onSelectChart={handleSelectChart}
                    dateRange={dateRange}
                />
            </main>
        </>
    );
};

export default App;