import {PropsWithChildren, useCallback, useEffect, useRef} from "react";
import './modal.scss';

type Props = PropsWithChildren<{ onClose?: () => void; closeOutClick?: boolean, multipleSections?: boolean }>

const Modal = ({children, onClose, closeOutClick = true, multipleSections = false}: Props) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const excludedClasses = ['rebus-select__icon-remove', 'rebus-select__option'];
    const mouseDownInsideRef = useRef(false);


    useEffect(() => {
        document.body.style.overflow = 'hidden';
        document.body.style.marginRight = '8px';
        return () => {
            document.body.style.overflow = 'unset';
            document.body.style.marginRight = '0';
        };
    }, []);

    const handleMouseDown = useCallback(({target}: MouseEvent) => {
       const isExcluded =
           excludedClasses.some(className => (target as HTMLElement).classList.contains(className));
        if (ref.current?.contains(target as HTMLElement) || isExcluded) {
            mouseDownInsideRef.current = true;
        }
    }, []);

    const handleMouseUp = useCallback(({target}: MouseEvent) => {
        const isExcluded =
            excludedClasses.some(className => (target as HTMLElement).classList.contains(className));
        if (mouseDownInsideRef.current || ref.current?.contains(target as HTMLElement) || isExcluded) {
            mouseDownInsideRef.current = false;
            return;
        }
        onClose?.();
    }, [onClose]);

    useEffect(() => {
        if (closeOutClick) {
            document.addEventListener('mousedown', handleMouseDown);
            document.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [closeOutClick, handleMouseDown, handleMouseUp]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                (document.activeElement as HTMLElement)?.blur();
                onClose?.();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="modal">
            <div className="modal-card" ref={ref}>
                <div className={multipleSections ? "modal-card-inner-multiple" : "modal-card-inner"}>
                    {children}
                </div>
            </div>
        </div>
    )
};

export default Modal;