import QuestionsList from "../List";
import QuestionsFilter from "../Filter";
import QuestionsActionsPanel from "../ActionsPanel";
import {Questions, QuestionsFilterItem, Period} from "../../../config/types";
import React, {SyntheticEvent, useEffect, useMemo, useRef, useState} from "react";
import Modal from "../../Modal";
import MarksManageModal from "../AddMarksModal";
import dayjs from "dayjs";

type Props = {
    list: Questions[];
    questionsChart: Questions[];
    onChange: (list: Questions[]) => void;
    onSelectChart: (item: Questions) => void;
    dateRange: Period;
};

function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const QuestionsContainer = ({list, questionsChart, onChange, onSelectChart, dateRange}: Props) => {

    const token = localStorage.getItem('token') ?? "";
    const [tags, setTags] = useState<QuestionsFilterItem[]>([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchTags = async () => {
            if (!list)
                return;
            const response = await fetch(`/api/tags/get-tags?token=${token}`);
            const data = await response.json();
            if (!Array.isArray(data)) {
                console.error('Expected an array from /api/tags/get_tags, got:', data);
                // data = []; change const to let
            }
            const visibleQuestions = list.filter((q) => !q.isHidden);
            const tagsWithCounts = data.map((tag: { name: string; id: number; isUserTag: boolean; }) => ({
                id: tag.id,
                label: tag.name,
                count: tag.isUserTag ? visibleQuestions.filter((q) => q.users.includes(tag.id)).length
                    : visibleQuestions.filter((q) => q.marks.includes(tag.id)).length,
                type: tag.isUserTag ? 'users' : 'marks',
                markType: tag.name,
            }));
            const noTagCount = visibleQuestions.filter((q) => q.marks.length === 0).length;
            setTags([
                {
                    id: -1,
                    count: noTagCount,
                    label: 'Без метки',
                    type: null,
                },
                ...tagsWithCounts,
            ]);
        };

        fetchTags();
    }, [list]);

    const [filter, setFilter] = useState<string>('');
    const [selected, setSelected] = useState<Questions[]>([]);

    const [badgeFilter, setBadgeFilter] = useState<QuestionsFilterItem[]>([]);
    const [hiddenFilter, setHiddenFilter] = useState<boolean>(false);

    const [unionName, setUnionName] = useState<string | null>(null);
    const [markManageModal, setMarkManageModal] = useState<'add' | 'remove' | null>(null);

    const prevBadgeFilter = usePrevious(badgeFilter);
    const prevTags = usePrevious(tags);

    useEffect(() => {
        if (tags.length > 0 && list.length > 0) {
            let reset = false;
            if (prevBadgeFilter === null || prevBadgeFilter?.length === 0) {
                reset = true;
            } else {
                let updatedBadgeFilter = prevBadgeFilter?.filter(prevTag =>
                    tags.some(tag => tag.id === prevTag.id)
                );

                if (prevTags && prevTags.length === prevBadgeFilter?.length && tags.length !== prevTags.length) {
                    const newTags = tags.filter(tag => !prevTags.some(prevTag => prevTag.id === tag.id));
                    updatedBadgeFilter = (updatedBadgeFilter ?? []).concat(newTags);
                }

                if (updatedBadgeFilter != null && updatedBadgeFilter.length > 0)
                    setBadgeFilter(updatedBadgeFilter);
                else
                    reset = true;
            }

            if (reset) {
                setBadgeFilter([
                    ...tags
                ]);
            }
        }
    }, [tags]);

    const filtered = useMemo(() => {
        const result: Questions[] = [];
        const addedIds = new Set();

        list.forEach((item) => {
            if (hiddenFilter && item.isHidden) {
                result.push(item);
                addedIds.add(item.cluster_ids[0]);
            } else if (!item.isHidden) {
                badgeFilter.forEach((filter) => {
                    if (filter.type === null) {
                        if (item.badge === null && !addedIds.has(item.cluster_ids[0]) && item.marks.length === 0) {
                            result.push(item);
                            addedIds.add(item.cluster_ids[0]);
                        }
                    }
                    if (filter.type === 'marks' && filter.markType && item.marks.includes(filter.id) && !addedIds.has(item.cluster_ids[0])) {
                        result.push(item);
                        addedIds.add(item.cluster_ids[0]);
                    }
                    if (filter.type === 'users' && filter.markType && item.users.includes(filter.id) && !addedIds.has(item.cluster_ids[0])) {
                        result.push(item);
                        addedIds.add(item.cluster_ids[0]);
                    }
                });
            }
        });

        if (filter.length > 1) {
            return result.filter(item => item.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
        }
        return result;
    }, [list, filter, badgeFilter, hiddenFilter]);

    const handleToggle = (item: Questions | null, index: number[] | null, isDeselect: boolean = false) => {
        if (item === null && index === null)
            return;

        let newSelected = [...selected];

        if (index != null) {
            const start = Math.min(index[0], index[1]);
            const end = Math.max(index[0], index[1]);

            if(isDeselect) {
                console.log("WE'RE NOW DESELECTING");
                const deselectedItems = filtered.slice(start, end + 1);
                newSelected = newSelected.filter(sel=>
                    !deselectedItems.some(item => item.cluster_ids[0] === sel.cluster_ids[0]));
            } else {
                for (let i = start; i <= end; i++) {
                    const currentItem = filtered[i];
                    if (!newSelected.some(sel => sel.cluster_ids[0] === currentItem.cluster_ids[0])) {
                        newSelected.push(currentItem);
                    }
                }
            }
        } else if (item != null) {
            if (newSelected.find(sel => sel.cluster_ids[0] === item.cluster_ids[0])) {
                newSelected = newSelected.filter(sel => sel.cluster_ids[0] !== item.cluster_ids[0]);
            } else {
                newSelected.push(item);
            }
        }

        setSelected(newSelected);
    };

    const onHide = async () => {
        const clusterIds = selected.flatMap(sel => sel.cluster_ids).join(',');
        const requestData = {
            token: token,
            cluster_ids: clusterIds
        };
        const response = await fetch(`/api/clusters/hide`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });
        const data = await response.json();

        if (data.success) {
            const result = list.map((item) => {
                if (selected.some(sel => sel.cluster_ids.includes(item.cluster_ids[0]))) {
                    item.isHidden = true;
                }
                return item;
            });
            onChange(result);
            setSelected([]);
        }
    };

    const onShow = async () => {
        const clusterIds = selected.flatMap(sel => sel.cluster_ids).join(',');
        const requestData = {
            token: token,
            cluster_ids: clusterIds
        };

        const response = await fetch(`/api/clusters/show`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        });
        const data = await response.json();

        if (data.success) {
            const result = list.map((item) => {
                if (selected.some(sel => sel.cluster_ids.includes(item.cluster_ids[0]))) {
                    item.isHidden = false;
                }
                return item;
            });
            onChange(result);
            setSelected([]);
        }
    };

    const handleUnionClick = () => {
        const allHidden = selected.every((item) => item.isHidden);
        const allVisible = selected.every((item) => !item.isHidden);
        if (selected.length < 1)
            return;

        if (!allHidden && !allVisible) {
            alert('Все выбранные вопросы должны быть либо скрыты, либо видимы');
            return;
        }

        setError('');

        const mostRelevantCluster = selected.reduce((max, question) => (question.count > max.count ? question : max), selected[0]);

        setUnionName(mostRelevantCluster.label);
    };


    const handleUnion = async (event: SyntheticEvent) => {
        event.preventDefault();

        if (unionName === null || !unionName.trim()) {
            setError('Название не может быть пустым!');
            return;
        }

        const hidden = selected.every((item) => item.isHidden);
        const clusterIds = [selected.map((item) => item.cluster_ids.join(','))].join(',');
        const groupIds = [selected.map((item) => item.group_id)].join(',');

        const requestData = {
            token: token,
            group_ids: groupIds,
            cluster_ids: clusterIds,
            title: unionName,
            start_date: dateRange[0] ? dayjs(dateRange[0]).format('YYYY-MM-DD') : '',
            end_date: dateRange[1] ? dayjs(dateRange[1]).format('YYYY-MM-DD') : ''
        };

        let fetchURL = '';

        if (selected.length > 1) {
            fetchURL = `/api/groups/create`;
        } else {
            fetchURL = `/api/clusters/rename`;
        }

        try {
            const response = await fetch(fetchURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });
            const data = await response.json();

            if (data.success) {
                const newList = list.filter((item) => !selected.some((sel) => sel.cluster_ids[0] === item.cluster_ids[0]));

                newList.push({
                    group_id: data.data[0].group_id,
                    cluster_ids: data.data[0].cluster_ids,
                    label: data.data[0].label,
                    badge: null,
                    count: data.data[0].count,
                    marks: data.data[0].marks,
                    users: data.data[0].users,
                    isHidden: hidden,
                });

                newList.sort((a, b) => b.count - a.count);
                onChange(newList);
            } else {
                alert(`Произошла ошибка: ${data.error_message}`);
            }
        } catch (error) {
            alert(`Произошла ошибка: ${error}`);
        }

        setSelected([]);
        setUnionName(null);
    };


    const handleSaveAddRemove = (updatedQuestions: Questions[]) => {
        const newList = list.filter((item) => !selected.some((sel) => sel.cluster_ids[0] === item.cluster_ids[0]));

        newList.push(...updatedQuestions);
        newList.sort((a, b) => b.count - a.count);
        onChange(newList);
        setSelected([]);
        setMarkManageModal(null);
    };

    return (
        <div className="questions">
            <div className="questions__input">
                <input
                    type="text"
                    placeholder="Поиск..."
                    value={filter}
                    onChange={({target}) => setFilter((target as HTMLInputElement).value)}
                />
            </div>
            <div className="questions-list">
                <div className="questions-list__header">
                    <QuestionsFilter
                        filter={badgeFilter}
                        hiddenFilter={hiddenFilter}
                        onChange={setBadgeFilter}
                        onToggleHidden={() => setHiddenFilter(prev => !prev)}
                        totalQuestionsCount={list.length}
                        hiddenQuestionsCount={list.filter((q) => q.isHidden).length}
                        tags={tags}
                        badgeFilter={badgeFilter}
                    />
                    <span className="questions-list__count">Кол-во</span>
                </div>
                <QuestionsList
                    hiddenActive={hiddenFilter}
                    questionsChart={questionsChart}
                    selected={selected}
                    substring={filter}
                    list={filtered}
                    onToggle={handleToggle}
                    onSelectChart={onSelectChart}
                    dateRange={dateRange}
                />
            </div>
            {selected.length > 0 && (
                <QuestionsActionsPanel
                    multipleSelected={selected.length > 1}
                    onClose={() => setSelected([])}
                    onHide={onHide}
                    onShow={onShow}
                    onUnion={() => handleUnionClick()}
                    onAdd={() => setMarkManageModal('add')}
                    onRemove={() => setMarkManageModal('remove')}
                />
            )}
            {unionName !== null && (
                <Modal
                    onClose={() => setUnionName(null)}
                >
                    <form
                        className="modal-inner"
                        onSubmit={handleUnion}
                    >
                        <div className="modal-title">{
                            selected.length > 1
                                ? "Название после объединения"
                                : "Новое название"
                        }</div>
                        <div className="modal-content">
                            <input
                                type="text"
                                className={`${error ? "input-error" : ""}`}
                                value={unionName}
                                onChange={({target}) => {
                                    setUnionName(target.value);
                                    setError('');
                                }}
                            />
                            {error && <div className="input-error-message">{error}</div>}
                        </div>
                        <div className="modal-actions">
                            <button
                                data-secondary="true"
                                onClick={() => setUnionName(null)}
                            >Отменить
                            </button>
                            <button
                                data-accent="true"
                                type="submit"
                            >Сохранить
                            </button>
                        </div>
                    </form>
                </Modal>
            )}
            {markManageModal === 'add' && (
                <MarksManageModal
                    mode="add"
                    selected={selected}
                    onClose={() => setMarkManageModal(null)}
                    onSave={handleSaveAddRemove}
                    tags={tags.map(tag => ({
                        id: tag.id,
                        label: tag.label,
                        isUserTag: tag.type === 'users'
                    }))}
                    dateRange={dateRange}
                />
            )}
            {markManageModal === 'remove' && (
                <MarksManageModal
                    mode="remove"
                    selected={selected}
                    onClose={() => setMarkManageModal(null)}
                    onSave={handleSaveAddRemove}
                    tags={tags.map(tag => ({
                        id: tag.id,
                        label: tag.label,
                        isUserTag: tag.type === 'users'
                    }))}
                    dateRange={dateRange}
                />
            )}
        </div>
    );
};

export default QuestionsContainer;