import React from 'react';
import {Navigate} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

interface Props {
  children: React.ReactNode;
}

function PrivateRoute({ children }: Props): JSX.Element {
    const isAuthenticated = useAuth();

    return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
}
export default PrivateRoute;

