import RebusSelect from "../../RebusSelect";
import Modal from "../../Modal";
import React, {SyntheticEvent, useState} from "react";
import {Period, Questions, SelectOption} from "../../../config/types";
import dayjs from "dayjs";

type Props = {
    mode: 'add' | 'remove';
    selected: Questions[];
    onSave: (values: Questions[]) => void;
    onClose: () => void;
    tags: SelectOption[];
    dateRange: Period;
};

const MarksManageModal = ({mode, selected, onClose, onSave, tags, dateRange}: Props) => {
    const token = localStorage.getItem('token') ?? "";
    const filteredTags = tags.filter(tag => {
        if (tag.id === -1) return false;

        if (tag.isUserTag) {
            return mode === 'add'
                ? selected.some(question => !question.users.includes(tag.id))
                : selected.some(question => question.users.includes(tag.id));
        } else {
            return mode === 'add'
                ? !selected.some(question => question.marks.includes(tag.id))
                : selected.some(question => question.marks.includes(tag.id));
        }
    });
    const [values, setValues] = useState<SelectOption[]>([]);

    const handleAdd = async (event: SyntheticEvent) => {
        event.preventDefault();

        const clusterIds = selected.map((item) => item.cluster_ids.join(',')).join(',');
        const existingTagIds = values.filter((val) => val.id !== -1).map((val) => val.id).join(',');
        const newTagNames = values.filter((val) => val.id === -1).map((val) => val.label).join(',');
        const isUserTag = values.some((val) => val.isUserTag);

        try {
            let response;
            const startDate = dateRange[0] ? dayjs(dateRange[0]).format('YYYY-MM-DD') : '';
            const endDate = dateRange[1] ? dayjs(dateRange[1]).format('YYYY-MM-DD') : '';

            const requestBody = {
                token: token,
                cluster_ids: clusterIds,
                start_date: startDate,
                end_date: endDate,
                tag_ids: existingTagIds ?? '',
                tag_names: newTagNames ?? '',
                is_user_tag: isUserTag
            };

            if (mode === 'add') {
                response = await fetch('/api/tags/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                });
            } else {
                response = await fetch('/api/tags/remove', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestBody)
                });
            }
            const data = await response.json();

            if (data.success) {
                const updatedQuestions: Questions[] = data.data.map((item: Questions) => ({
                    group_id: item.group_id,
                    cluster_ids: item.cluster_ids,
                    label: item.label,
                    badge: null,
                    count: item.count,
                    marks: item.marks,
                    users: item.users,
                    isHidden: item.isHidden,
                }));
                onSave(updatedQuestions);
            } else {
                alert(`Произошла ошибка: ${data.error_message}`);
            }
        } catch (error) {
            alert(`Произошла ошибка: ${error}`);
        }
    };

    return (
        <Modal onClose={onClose}>
            <form className="modal-inner" onSubmit={handleAdd}>
                <div className="modal-title">{mode === 'add' ? 'Добавить метку' : 'Удалить метку'}</div>
                <div className="modal-content">
                    <RebusSelect
                        placeholder="Название метки"
                        options={filteredTags}
                        value={values}
                        onChange={setValues}
                    />
                </div>
                <div className="modal-actions">
                    <button data-secondary="true" type="button" onClick={onClose}>Отменить</button>
                    <button data-accent="true" type="submit">{mode === 'add' ? 'Добавить' : 'Удалить'}</button>
                </div>
            </form>
        </Modal>
    );
};

export default MarksManageModal;