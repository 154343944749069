import React, {useCallback, useEffect, useRef} from "react";
import {CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from "chart.js";

import {Chart as ChartJS} from 'chart.js/auto';
import './chart.scss';
import {Questions, Period} from "../../config/types";
import dayjs from "dayjs";
import moment from "moment";
import 'moment/locale/ru';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

type Props = {
    questions: Questions[];
    dateRange: Period;
    daysCount: number;
};

function getRandomColor() {
    const minBrightness = 55;
    const colorRange = 255 - minBrightness;

    const r = Math.floor(Math.random() * colorRange) + minBrightness;
    const g = Math.floor(Math.random() * colorRange) + minBrightness;
    const b = Math.floor(Math.random() * colorRange) + minBrightness;

    const mainColor = '#' + [r, g, b].map(channel => channel.toString(16).padStart(2, '0')).join('');
    const backColor = '#' + [r, g, b].map(channel => {
        const backChannel = Math.floor(channel * 0.5);
        return backChannel.toString(16).padStart(2, '0');
    }).join('');

    return [mainColor, backColor];
}

const ChartLine = ({questions, dateRange, daysCount}: Props) => {
    const token = localStorage.getItem('token') ?? "";
    const refChart = useRef<Chart | null>(null);

    const colors = [
        ['#8CFA49', '#467A24'],
        ['#0089FF', '#00447F'],
        ['#FF007A', '#7F003D'],
        ['#00F0FF', '#007F7F'],
        ['#FAE849', '#7D7424'],
        ['#9C4FFF', '#4E287F'],
        ['#FF1842', '#7F0C21'],
        ['#FFFFFF', '#7F7F7F'],
        ['#0038FF', '#001C7F'],
        ['#FF7A00', '#7F3D00']
    ];

    moment.locale('ru');

    useEffect(() => {
        if (!refChart.current) {
            return;
        }

        refChart.current.data.labels = dateRange[0] ? Array.from({length: daysCount}, (_, i) => {
            const date = new Date(dateRange[0] ? dateRange[0] : new Date(2024, 0, 1));
            date.setDate(date.getDate() + i);
            //return dayjs(date).format('DD.MM');
            return moment(date).format('D MMM').slice(0, -1);
        }) : [];

        refChart.current.update();
    }, [dateRange, daysCount]);

    const fetchDataCounts = async (clusterIds: number[], dateRange: Period) => {
        const startDate = dayjs(dateRange[0]).format('YYYY-MM-DD');
        const endDate = dayjs(dateRange[1]).format('YYYY-MM-DD');
        const response = await fetch(
            `/api/datecounts?token=${token}&start_date=${startDate}&end_date=${endDate}&cluster_ids=${clusterIds.join(',')}`
        );
        const data = await response.json();
        return data.success ? data.data : [];
    };


    useEffect(() => {
        if (!refChart.current) {
            return;
        }

        const updateQuestionData = async (item: Questions, index: number) => {
            const clusterIds = item.cluster_ids;
            const dataCounts = await fetchDataCounts(clusterIds, dateRange);
            const color = colors[index % colors.length];

            return {
                label: item.label,
                data: dataCounts,
                fill: false,
                tension: 0.4,
                cubicInterpolationMode: 'monotone',
                borderColor: color[0],
                pointBorderColor: color[0],
                pointBackgroundColor: color[1]
            };
        };

        const updateChartData = async () => {
            if (!refChart.current) {
                return;
            }
            refChart.current.data.datasets = await Promise.all(questions.map(updateQuestionData));
            refChart.current.update();
        };

        updateChartData();
    }, [questions, dateRange]);


    const initRef = useCallback((ref: HTMLCanvasElement | null) => {
        if (ref) {
            refChart.current = new Chart(ref, {
                type: 'line',
                // @ts-ignore
                data: [],
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'bottom',
                            labels: {
                                usePointStyle: true,
                                pointStyle: 'circle',
                            }
                        },
                        title: {
                            display: false,
                        },
                    },
                    interaction: {
                        intersect: false,
                    },
                    scales: {
                        x: {
                            grid: {
                                color: 'rgba(73, 73, 73, 0.5)',
                            },
                            display: true,
                            title: {
                                display: true
                            }
                        },
                        y: {
                            grid: {
                                color: 'rgba(73, 73, 73, 0.5)',
                            },
                            display: true,
                            title: {
                                display: true,
                                text: 'Количество'
                            },
                            suggestedMin: 0,
                            suggestedMax: 35
                        }
                    }
                },
            });
        }
    }, []);

    return (
        <div className="chart">
            <div className="chart-card">
                <canvas ref={initRef} height="194"></canvas>
            </div>
        </div>
    );
};

export default ChartLine;