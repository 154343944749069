import {ActionMeta, MultiValue, StylesConfig} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import React from "react";
import {SelectOption} from "../../config/types";
import {components} from 'react-select';

const colourStyles: StylesConfig<SelectOption, true> = {
    control: (styles) => ({
        ...styles,
        backgroundColor: '#252525',
        borderColor: 'rgba(89, 89, 89, 0.5)!important',
        borderRadius: '14px',
        height: '53px',
        boxShadow: 'none!important',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    menuList: (styles) => ({
        ...styles,
        padding: 0,
    }),
    multiValue: (styles) => ({
        ...styles,
        minHeight: '39px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "#383838",
        borderRadius: "6px",
        padding: '0 14px',
        margin: 0,
        marginRight: '5px',
        fontFamily: 'Inter Variable',
        fontSize: '18px',
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: "#252525",
    }),
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#FFFFFF'
    }),
    indicatorsContainer: () => ({
        display: 'none',
    }),
    input: (styles) => ({
        ...styles,
        color: "#ffffff",
        'input': {
            height: 'unset',
            fontFamily: 'inherit'
        }
    }),
    option: (styles) => ({
        ...styles,
        backgroundColor: "#252525!important",
    }),
    placeholder: (styles) => ({...styles}),
    singleValue: (styles, {data}) => ({...styles,}),
    multiValueRemove: (styles, {data}) => ({
        ...styles,
        backgroundColor: 'transparent!important',
        color: '#FFFFFF!important'
    }),
};

type Props = {
    placeholder?: string;
    options: SelectOption[];
    value: SelectOption[];
    onChange: (value: SelectOption[]) => void;
}

const RebusSelect = ({value, options, onChange, placeholder}: Props) => {

    const handleChange = (newValue: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) => {
        if (actionMeta.action === 'select-option' || actionMeta.action === 'create-option') {
            const newValues = newValue ? [...newValue] : [];
            onChange([...value, ...newValues]);
        } else if (actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value') {
            onChange(newValue ? [...newValue] : []);
        }
    };

    return (
        <CreatableSelect
            isMulti
            placeholder={placeholder}
            className="rebus-select"
            classNamePrefix="rebus-select"
            components={{
                MultiValueRemove: (props) => (
                    <components.MultiValueRemove {...props}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 16 16" className="rebus-select__icon-remove">
                            <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                    </components.MultiValueRemove>
                )
            }}
            menuPosition="fixed"
            styles={colourStyles}
            options={options}
            value={value}
            onChange={(values) => {
                onChange(values as SelectOption[]);
            }}
            noOptionsMessage={() => 'Ничего не найдено'}
            isValidNewOption={(inputValue, selectValue, selectOptions) => {
                const options = selectOptions as SelectOption[];
                return inputValue.trim() !== '' && !options.some(option => option.label === inputValue);
            }}
            getNewOptionData={(inputValue, optionLabel) => ({
                id: -1,
                label: optionLabel as string,
                isUserTag: false
            })}
            formatCreateLabel={(inputValue) => `Создать "${inputValue}"`}
        />
    )
};

export default RebusSelect;